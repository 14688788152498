import docReady from '../../../assets/js/docReady.js';

class ProviderDisplayComponent {
    constructor(element) {
        this.element = element;

        this.toggleBar = {
            tabList: [],
        };

        this.providers = {
            providerList: [],
        };
    }

    init() {
        this.bootstrap();

        this.toggleBar.tabList.forEach((tab) => {
            tab.element.addEventListener('click', () => {
                this.filterSelection(tab);
            });
        });
    }

    bootstrap() {
        this.element.querySelectorAll('.toggle-bar > .tab').forEach((tab) => {
            this.toggleBar.tabList.push({ element: tab, category: tab.dataset.categorySelector });
        });

        this.element.querySelectorAll('.link-list > .provider-button').forEach((provider) => {
            this.providers.providerList.push({ element: provider, category: provider.dataset.category });
        });
    }

    filterSelection(selectedTab) {
        this.toggleBar.tabList.forEach((tab) => {
            if (tab === selectedTab) {
                tab.element.classList.add('active');
            } else {
                tab.element.classList.remove('active');
            }
        });

        if (selectedTab.category === 'all') {
            this.providers.providerList.forEach((provider) => {
                provider.element.hidden = false;
            });
        } else {
            this.providers.providerList.forEach((provider) => {
                if (provider.category === selectedTab.category) {
                    provider.element.hidden = false;
                } else {
                    provider.element.hidden = true;
                }
            });
        }
    }
}

docReady(() => {
    const providerDisplayCategorizedElements = document.querySelectorAll('.provider-link-block-component');
    providerDisplayCategorizedElements.forEach((element) => {
        const providerDisplayCategorizedComponent = new ProviderDisplayComponent(element);
        providerDisplayCategorizedComponent.init();
    });
});
